import request from '@/utils/request'
export function queryUserMember (data) {
    return request({
        url: `/customer-service/userMember/queryUserMember?userId=${data}`,
        method: 'post',
        data
    })
}
export function orderOSM (pubId) {
    return request({
        url: `/order-service/orderOSM/get/${pubId}`,
        method: 'post'
    })
}
export function listOfPage () {
    return request({
        url: `/goods-service/store/listOfPage`,
        method: 'get'
    })
}